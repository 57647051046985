import React from "react";

import Abstinence from "./Abstinence";
import Belltower from "./Belltower";
import Book from "./Book";
import Broadcast from "./Broadcast";
// import Calendar from "./Calendar"; FIXME: Need to export default component
import Cathedral from "./Cathedral";
import Chapel from "./Chapel";
import Church from "./Church";
import City from "./City";
import CityObject from "./CityObject";
import Hermitage from "./Hermitage";
import Holiday from "./Holiday";
import Ikon from "./Ikon";
// import Media from "./Media";
import Monastery from "./Monastery";
import Museum from "./Museum";
import News from "./News";
import Radio from "./Radio";
import Saint from "./Saint";
import SaintBook from "./SaintBook";
import SerafimProject from "./SerafimProject";
import Shrine from "./Shrine";
import Temple from "./Temple";
import NewsPhotos from "./Media/NewsPhotos";
import TextPages from "./TextPages/Default";
import Tour from "./Tour";
import TV from "./TV";
import Video from "./Video";
import VirtualTour from "./VirtualTour";
import Well from "./Well";
import Page404 from "./404";
import Article from "./Media/Article";

function DefaultPreview() {
	return <div className="container">
		{"No Data"}
	</div>;
}

export {
	Abstinence,
	Belltower,
	Book,
	Broadcast,
	Cathedral,
	Chapel,
	Church,
	City,
	CityObject,
	Hermitage,
	Holiday,
	Ikon,
	Monastery,
	Museum,
	News,
	Radio,
	Saint,
	SaintBook,
	SerafimProject,
	Shrine,
	Temple,
	TextPages,
	Tour,
	TV,
	Video,
	VirtualTour,
	Well,
	DefaultPreview,
	Page404,
	Article,
	NewsPhotos,
};
