export default [
	"архидиакона",
	"архиепископа",
	"архимандрита",
	"болящей",
	"болящего",
	"воина",
	"диакона",
	"епископа",
	"заблудшего",
	"заблудшей",
	"заключенного",
	"заключенной",
	"игумена",
	"игумена с братией",
	"игуменьи",
	"игуменьи с сестрами",
	"иерея",
	"иеродиакона",
	"иеромонаха",
	"иеросхимонаха",
	"инока",
	"инокини",
	"иподиакона",
	"митрополита",
	"младенца",
	"монаха",
	"монахини",
	"непраздной",
	"новопреставленного",
	"новопреставленной",
	"отрока",
	"отроковицы",
	"патриарха",
	"послушника",
	"послушницы",
	"протодиакона",
	"протоиерея",
	"путешествующего",
	"путешествующей",
	"священника",
	"со чадом/чадами",
	"схиархиепископа",
	"схиархимандрита",
	"схиепископа",
	"схиигумена",
	"схиигуменьи",
	"схииеродиакона",
	"схимонаха",
	"схимонахини",
	"убиенного",
	"убиенной",
	"чтеца",
];
