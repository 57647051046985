import React, { Fragment, useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import {
	Form,
	Input,
	Button,
	Icon,
	Checkbox,
	Select,
	Modal,
	Result,
	Progress,
	AutoComplete,
	notification,
} from "antd";
import { isMobile } from "react-device-detect";
import { get, capitalize, trim } from "lodash";
import cx from "classnames";
import jwtDecode from "jwt-decode";

import { Icon as MeinIcon } from "../../Media";
import { withTrebs } from "../../../containers/withCrowdfunding";
import { Carousel } from "../../Carousel";
import { useThemeContext } from "../../Layout/ThemeContext";
import NoScript from "../../NoScript";
import titles from "./titles";
import { PROJECTS, TREB_NAMES } from "../../../queries/queries.graphql";
import { fundValidationRules, getEmailValidationRules } from "../../../utils";
import { formBWtheme } from "../../../utils";
import { ymNumber } from "../../../constants";
import { removeEmptyKeys } from "../../../helpers";

let id = 9;

const { Option } = Select;

function useCrowdfundingProjects() { // TODO: Move to hooks
	const [projects, setProjects] = useState([]);
	const { loading, error, data } = useQuery(PROJECTS);

	useEffect(() => {
		if (!error && data) {
			setProjects(get(data, "crowdfunding_projects", []));
		}
	}, [loading]);

	return { projects,
		projectsLoading: loading,
		projectsError: error };
}

function TrebsModalForm({ form, title, subTitle, icon, blockStyle, description, type, updateCurrentOrder }) {
	const { theme } = useThemeContext();
	const { getFieldDecorator, setFieldsValue, getFieldsValue, getFieldValue, validateFields, resetFields } = form;
	const { projects } = useCrowdfundingProjects();

	const [isMount, setIsMount] = useState(false);
	const [categories, setCategories] = useState([]);
	const [corpses, setCorpses] = useState([0]);
	const [amount, setAmount] = useState(1);
	const [carousel, setCarousel] = useState(null);
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState(false);
	const [steps, setSteps] = useState([]);
	const [complete, setComplete] = useState(false);
	const [preview, setPreview] = useState(false);
	const [names, setNames] = useState([]);
	const [index, setIndex] = useState(0);
	const [token, setToken] = useState(null);
	const [noPayment, setNoPayment] = useState(false);
	const [allTrebNames, setAllTrebNames] = useState([]);

	const options = [...new Set(names.map(item => item.replace))];
	const email = token ? get(jwtDecode(token), "user.email", "") : "";

	const { loading: loadingTrebNames, error: errorTrebNames, data: dataTrebNames } = useQuery(TREB_NAMES);

	useEffect(() => {
		setIsMount(true);
		setToken(localStorage.getItem("serafim_token"));
	}, []);

	useEffect(() => {
		if (!errorTrebNames && dataTrebNames) {
			setAllTrebNames(get(dataTrebNames, "names_for_trebs[0].items", []));
		}
	}, [loadingTrebNames]);

	function removeCorpse(name) {
		setCorpses(corpses.filter(corpse => corpse !== name));
	}
	// TODO: Need use useReduce 👇👆
	function addCorpse(corpse) {
		setCorpses([...corpses, corpse]);
	}

	function calculatePrice() {
		const { trebNames = [], trebCategory } = getFieldsValue();
		const currentCategory = categories.find(category => category.title === trebCategory);

		setAmount(+get(currentCategory, "price", 1) * +(trebNames.filter(value => trim(value)).length || 1));
	}

	function makeNames() {
		return corpses.map((name, idx) => (
			<Fragment key={name}>
				<Form.Item
					key={`treb-title-${idx}`}
					label={"Выберите титул"}
					className={cx("col-12 col-md-6", { "col-md-6": idx === corpses.length - 1 && !(idx % 2) })}
				>
					{getFieldDecorator(`trebTitles[${name}]`, {
						initialValue: "",
						rules: [
							{
								message: "Выберите титул!",
							},
						],
					})(
						<Select
							showSearch
							allowClear
							optionFilterProp="children"
						>
							<Option key={"empty"} value={""} style={{ height: "37px" }}/>
							{titles.map(title => {
								return (
									<Option key={title} value={title}>
										{title}
									</Option>
								);
							})}
						</Select>,
					)}
				</Form.Item>
				<Form.Item
					key={idx}
					label={"Введите имя"}
					className={cx("col-12 col-md-6", { "col-md-6": idx === corpses.length - 1 && !(idx % 2) })}
				>
					{getFieldDecorator(`trebNames[${name}]`, {
						initialValue: "",
						normalize: capitalize,
					})(
						<AutoComplete
							onChange={value => setNames(allTrebNames.filter(({ name }) => !!value && new RegExp(`^${value}`, "gi").test(name)))}
							dataSource={options}
						>
							<Input
								suffix={
									corpses.length > 1 && <Icon
										onClick={event => {
											event.preventDefault();
											event.stopPropagation();

											removeCorpse(name);
										}}
										style={{ cursor: "pointer" }}
										type="close-circle"
									/>
								}
								onPressEnter={() => getFieldValue(`trebNames[${name}]`) && corpses.length < 10 && addCorpse(++id)}
							/>
						</AutoComplete>,
					)}
				</Form.Item>
			</Fragment>
		));
	}

	function pay(paymentData = null, callback = () => Function) {
		if (typeof cp !== "undefined" && paymentData) {
			// eslint-disable-next-line no-undef
			const widget = new cp.CloudPayments({ language: "ru" });

			widget.charge(
				Object.assign({}, paymentData, {
					skin: "mini",
				}),
				() => {
					setLoading(false);
					setComplete(true);
					resetFields(["trebNames", "trebTitles"]);

					callback();
					notification.success({
						message: "Платёж Выполнен",
					});

					if (carousel) {
						carousel.update();
						carousel.slideNext();
					}
				},
				() => {
					setLoading(false);
					notification.error({
						message: "Платёж отменён",
					});
					callback();
				},
			);
		} else {
			setLoading(false);
			notification.error({
				message: "Ошибка загрузки платёжного виджета",
			});
		}
	}

	function fireYandexMetricaGoal() {
		if (typeof window !== "undefined" && window.ym && process.env.NODE_ENV === "production") {
			// eslint-disable-next-line no-undef
			ym(
				ymNumber,
				"reachGoal",
				"trebs-name-count",
				{
					AB: {
						"trebs-name-count": [corpses.length],
					},
				},
			);
		}
	}

	function payLoad(variables, isPay = true) {
		if (variables) {
			updateCurrentOrder({
				variables,
			})
				.then(response => {
					const paymentData = get(response, "data.updateCurrentOrder.order.paymentData", null);

					if (isPay) {
						pay(paymentData, fireYandexMetricaGoal);
					} else if (typeof window !== "undefined") {
						console.info("Должна скачаться треба но УВЫ!");
						setComplete(true);
						setLoading(false);
						window.open("https://serafim.help/orders/preview");
						if (carousel) {
							carousel.update();
							carousel.slideNext();
						}
					}
				})
				.catch(error => {
					setLoading(false);
					console.error(error);
					notification.error({
						message: "Ошибка формы",
					});
				});
		} else {
			notification.error({
				message: "Ошибка формы",
			});
		}
	}

	function emptyTrebNames() {
		return isMount ? !!getFieldValue("trebNames").reduce((result, name) => {
			if (trim(name)) {
				result++;
			}

			return result;
		}, 0) : false;
	}

	function handleSubmit(pay = true) {
		setLoading(pay);
		setPreview(!pay);

		form.validateFields((error, values) => {
			if (!error) {
				const trebNames = values.trebNames.reduce((result, name, idx) => {
					const title = values.trebTitles[idx];
					if (trim(name)) {
						result.push(`${title ? `${title} ` : ""}${name}`);
					}

					return result;
				}, []);

				const amount = parseInt(values.amount, 10);

				delete values.trebTitles;

				payLoad(Object.assign(
					{},
					values,
					{
						trebNames,
						amount,
					},
				), pay);
			} else {
				setLoading(false);
				notification.error({
					message: "Некорректные данные формы",
				});
			}

			carousel.update();
		});
	}

	function makeSlides() {
		const nameSlide = <div
			key={"form-item-1"}
			data-fields={"crowdfundingProjectId,trebCategory"}>
			{
				<Form.Item label={"Куда подать записку"}>
					{getFieldDecorator("crowdfundingProjectId", {
						initialValue: get(projects, "[0].id", ""),
						rules: [
							{
								required: true,
								message: "Выберите монастырь!",
							},
						],
					})(
						<Select disabled={!projects.length} onSelect={value => setCategories(get(projects.find(item => item.id === value), `treb_set.config[${type}].categories`, []))}>
							{projects.map(({ data, id }) => (
								<Option key={data.place} value={id}>
									{data.place}
								</Option>
							))}
						</Select>,
					)}
				</Form.Item>
			}
			<Form.Item label={"Выберите тип поминовения"}>
				{getFieldDecorator("trebCategory", {
					initialValue: get(categories, "[0].title", ""),
					rules: [
						{
							required: true,
							message: "Выберите тип поминовения!",
						},
					],
				})(
					<Select
						disabled={!categories.length}
						onSelect={value => {
							const currentCategory = categories.find(category => category.title === value);
							setNoPayment(get(currentCategory, "nopayment", false));
							setSteps(get(currentCategory, "steps", []));
						}}
					>
						{categories.map(({ title }) => (
							<Option key={title} value={title}>
								{title}
							</Option>
						))}
					</Select>,
				)}
			</Form.Item>
			<Form.Item>
				<span className={"d-md-block"} style={removeEmptyKeys({ color: theme.color.text })} dangerouslySetInnerHTML={{ __html: description }}/>
			</Form.Item>
		</div>;

		const isAnonymous = form.getFieldValue("anonymous");

		const emailSlide = <div key={"form-item-2"} data-fields={"email,anonymous"}>
			{formBWtheme(isAnonymous, theme.color.text)}
			<Form.Item label={"Введите E-mail"}>
				{getFieldDecorator("email", {
					initialValue: email,
					rules: getEmailValidationRules(!isAnonymous),
				})(
					<Input
						type={"email"}
						disabled={isAnonymous}
						placeholder={"Введите E-mail для отправки копии записки"}
					/>,
				)}
			</Form.Item>
			<Form.Item>
				{getFieldDecorator("anonymous", {
					valuePropName: "checked",
					initialValue: !email,
				})(<Checkbox style={removeEmptyKeys({ color: theme.color.text })}>Анонимная записка</Checkbox>)}
			</Form.Item>
			<Form.Item>
				<span style={removeEmptyKeys({ color: theme.color.text })}>
				Если Вы не укажете E-mail, мы не сможем выслать вам благодарность за
				пожертвование.
				</span>
			</Form.Item>
		</div>;

		const trebNames = <div key={"form-item-3"} data-fields={"trebNames"}>
			<Form.Item>
				<span className={"d-none d-md-block"} style={removeEmptyKeys({ color: theme.color.text })}>
				— В записках указываются имена только крещеных христиан православного
				вероисповедания, по одному слову в каждой строке. <b>Имена некрещеных и самоубийц в
				записках не пишутся.</b>
					<br />
				— В подаваемых записках необходимо записывать <b>церковные имена</b> (данные при святом
				крещении), указывая их <b>в родительном падеже</b> (например, Георгия, Анны).
					<br />
				— Можно использовать следующие слова перед именами за здравие: военного (воина),
				болящего (болящ.), путешествующего (путеш.); заключенного (закл.), беременной
				(непраздной) (непразд.). До 7 лет ребенок – младенец (например, мл. Сергия), с 7
				до 14 лет – отрок (например, отр. Евгения). Перед именами об упокоении можно
				указывать «убиенного», «новопреставленного» (до 40-го дня со дня смерти).
					<br />— При оформлении записки о здравии или об упокоении священнослужителя или
				монашествующего, необходимо указать сан: диакона, иеродиакона, иерея,
				протоиерея, иеромонаха, игумена, архимандрита, инока или монаха.
				</span>
			</Form.Item>
			<Form.Item>
				<span className={"d-md-none"} style={removeEmptyKeys({ color: theme.color.text })}>
					Поминаются только крещёные православные. Некрещёных и самоубийц писать нельзя.
				</span>
			</Form.Item>
			<Form.Item>
				{!emptyTrebNames() && corpses.length > 5 && <span className={"d-flex justify-content-center"} style={{ color: "red" }}>Заполните хотя бы одно поле с именем</span>}
			</Form.Item>
			<div className="row">{makeNames()}</div>
			<Form.Item>
				{!emptyTrebNames() && !isMobile && <span className={"d-flex justify-content-center"} style={{ color: "red" }}>Заполните хотя бы одно поле с именем!</span>}
			</Form.Item>
			<Form.Item>
				<Button
					disabled={corpses.length >= 10}
					type="dashed"
					onClick={() => addCorpse(++id)}
					style={Object.assign({},
						{ height: isMobile ? 90 : "" }, removeEmptyKeys({ color: theme.color.body,
							backgroundColor: theme.color.text }))}
					block
				>
					<Icon type="plus" /> Добавить имя (максимум 10) {isMobile ? <br/> : ""} осталось {10 - corpses.length}
				</Button>
			</Form.Item>
		</div>;

		const amountBlock = <div
			key={"form-item-4"}
			data-fields={"amount"}>
			<Form.Item label={`Рекомендуемая цена: ${amount} ₽`}>
				{getFieldDecorator("amount", {
					initialValue: 1,
					rules: fundValidationRules,
				})(
					<Input
						type={"number"}
						prefix={"₽"}
						placeholder={"Введите сумму"}
						onChange={() => carousel.update()}
						onBlur={() => carousel.update()}
					/>,
				)}
				<Button.Group
					style={{
						position: "absolute",
						right: 0,
					}}
				>
					{steps.map((step, idx) => {
						return (
							<Button
								key={idx}
								onClick={() =>
									setFieldsValue({
										amount: +getFieldValue("amount") + +step,
									})
								}
								style={removeEmptyKeys({
									fontFamily: theme.fontFamily,
									fontSize: theme.fontSize.text,
									lineHeight: theme.lineHeight.text,
									color: theme.color.body,
									backgroundColor: theme.color.text,
								})}
							>
					+{step}
							</Button>
						);
					})}
				</Button.Group>
			</Form.Item>
		</div>;

		const previewBlock = <div key={"form-item-5"}>
			<div className="row justify-content-center">
				<div className="p-4 col-8 col-md-4 d-flex justify-content-center flex-column trebs-preview">
					<span className={"d-flex justify-content-center mb-3 link"}>
						<MeinIcon id={"cross"} style={{ width: 40,
							height: 40 }} />
					</span>
					<h4 className={"mb-3"}
						style={Object.assign(
							{},
							removeEmptyKeys({ textAlign: "center",
								color: theme.color.text }))
						}>
						{getFieldValue("trebCategory")}
					</h4>
					<h5 className={"mb-3"} style={removeEmptyKeys({ textAlign: "center",
						color: theme.color.text })}>
						{title}
					</h5>
					<span style={removeEmptyKeys({ textAlign: "center",
						color: theme.color.text })}>
						{get(projects.find(project => project.id === getFieldValue("crowdfundingProjectId")), "data.place", "")}
					</span>
					<span
						className={"mb-3"}
						style={Object.assign({}, removeEmptyKeys({ textAlign: "center",
							color: theme.color.text }))}
						dangerouslySetInnerHTML={{
							__html: getFieldValue("trebNames").reduce((result, item, idx) => {
								if (trim(item)) {
									result = `${result}<br/>${getFieldValue("trebTitles")[idx] ? `${getFieldValue("trebTitles")[idx]} ` : ""} ${item}`;
								}

								return result;
							}, ""),
						}}
					/>
					<div className={"d-flex justify-content-center flex-column mt-auto"}>
						<span className={"d-flex justify-content-center mb-1 link"}>
							<MeinIcon id={"logo"} style={{ width: 40,
								height: 40 }} />
						</span>
						<h6 style={removeEmptyKeys({ width: "100%",
							textAlign: "center",
							color: theme.color.text })}>
								Серафимова Земля
						</h6>
					</div>
					<div className={"d-none justify-content-center"}>
						<Button
							icon={"download"}
							size={"small"}
							onClick={() => handleSubmit(false)}
							loading={preview}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							Скачать записку
						</Button>
					</div>
				</div>
			</div>
		</div>;

		const thankBlock = <div key={"form-item-6"} className={cx({ "d-none": !complete })}>
			<Result
				status={"success"}
				title={"Спасибо!"}
				subTitle={noPayment ? "Ваш заказ принят." : "Ваша оплата прошла."}
				style={{ textAlign: "center" }}
			>
				{!!getFieldValue("email") &&
				"В ближайшее время Вам будет направлено подтверждение на указанную электронную почту."}
			</Result>
		</div>;

		const slides = [
			nameSlide,
			emailSlide,
			trebNames,
			amountBlock,
			previewBlock,
			thankBlock,
		];

		return slides.filter(slide => slide);
	}

	useEffect(() => {
		setCategories(get(projects, `[0].treb_set.config[${type}].categories`, []));
	}, [projects]);

	useEffect(() => {
		calculatePrice();
		if (carousel) {
			carousel.update();
		}
	}, [corpses]);

	useEffect(() => {
		setFieldsValue({
			amount,
		});
	}, [amount]);

	useEffect(() => {
		calculatePrice();
	}, [steps]);

	useEffect(() => {
		resetFields(["trebCategory"]);
		calculatePrice();
		setSteps(get(categories, "[0].steps", []));
		setNoPayment(get(categories, "[0].nopayment", false));
	}, [categories]);

	const last = get(carousel, "slides", []).length - 2 === get(carousel, "activeIndex", true);

	getFieldDecorator("amount", {
		initialValue: 0,
	});

	getFieldDecorator("trebType", {
		initialValue: type,
	});

	// TODO: need to optimize call of render

	return (
		<div
			className={"trebs-item__wrapper"}
			style={Object.assign({}, blockStyle, removeEmptyKeys({
				fontFamily: theme.fontFamily,
				fontSize: theme.fontSize.text,
				lineHeight: theme.lineHeight.text,
				color: theme.color.text,
				backgroundColor: theme.color.body || "#6351446b",
			}))}
		>
			<style>
				{`
					.ant-modal-close-x {
						color: ${theme.color.text};
					}
				`}
			</style>
			<div className={"trebs-item__cross"}>{icon}</div>
			<div className={"trebs__text mb-3"}>
				{title}
			</div>
			<Button
				onClick={() => {
					if (carousel && complete) {
						carousel.slideTo(0);
					}

					setVisible(true);
					setComplete(false);
				}}
				className={"trebs__button d-noscript-none"}
				type={"primary"}
				style={removeEmptyKeys({
					fontFamily: theme.fontFamily,
					fontSize: theme.fontSize.text,
					lineHeight: theme.lineHeight.text,
					backgroundColor: theme.color.text,
					color: theme.color.body,
				})}
			>
				{title}
			</Button>
			<NoScript>
				<Button
					className={"trebs__button"}
					type={"primary"}
					disabled
				>
					{title}
				</Button>
			</NoScript>
			<NoScript>
				<p>Чтобы подать записку, включите JavaScript</p>
			</NoScript>
			<style>
				{`
					.ant-modal-close-x {
						color: ${theme.color.text};
					}
				`}
			</style>
			<Modal
				title={subTitle}
				visible={visible}
				onCancel={() => setVisible(false)}
				centered
				width={1020}
				style={removeEmptyKeys({ backgroundColor: theme.color.body })}
				wrapClassName={`modal-wrap-${theme.color.key}`}
				footer={[
					<div
						key={"submit-group"}
						className={"container-fluid px-0"}
					>
						<Progress
							size={"small"}
							percent={(() => {
								if (carousel) {
									return (
										100 / get(carousel, "slides", []).length * (index + 1)
									);
								}

								return 0;
							})()}
							style={{ marginBottom: 8 }}
							showInfo={false}
						/>
						<div className={"row"}>
							<div
								className={cx("col-6", {
									"d-none": !get(carousel, "activeIndex", false) || complete,
								})}
							>
								<Button
									block
									onClick={() => {
										calculatePrice();

										if (carousel) {
											carousel.slidePrev();
											if (token && noPayment && +carousel.activeIndex === 2) {
												carousel.slidePrev();
											} else if (!token && noPayment && +carousel.activeIndex === 3) {
												carousel.slidePrev();
											}
										}
									}}
									disabled={loading}
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.body,
										backgroundColor: theme.color.text,
									})}
								>
									Назад
								</Button>
							</div>
							<div
								className={cx("col-6", {
									"col-12": !get(carousel, "activeIndex", false) || complete,
								})}
							>
								<Button
									block
									type="primary"
									onClick={() => {
										if (!last && !complete && carousel) {
											calculatePrice();

											const fields = get(
												carousel,
												`slides[${+carousel.activeIndex}].dataset.fields`,
												"",
											).split(",");

											if (fields[0] === "trebNames") {
												if (!emptyTrebNames()) {
													return;
												}
											}

											validateFields(fields, errors => {
												if (errors) {
													notification.error({
														message: "Некорректные данные формы",
													});
												} else {
													carousel.slideNext();
													if (token && noPayment && +carousel.activeIndex === 2) {
														carousel.slideNext();
													} else if (!token && noPayment && +carousel.activeIndex === 3) {
														carousel.slideNext();
													}
												}
											});
										} else if (last && !complete) {
											handleSubmit(!noPayment);
										} else {
											setVisible(false);
										}
									}}
									disabled={loading}
									loading={loading}
									style={removeEmptyKeys({
										fontFamily: theme.fontFamily,
										fontSize: theme.fontSize.text,
										lineHeight: theme.lineHeight.text,
										color: theme.color.body,
										backgroundColor: theme.color.text,
									})}
								>
									{(() => {
										switch (true) {
											case last && !complete:
												return "Заказать";
											case !last && !complete:
												return "Далее";
											default:
												return "Закрыть";
										}
									})()}
								</Button>
							</div>
						</div>
					</div>,
				]}
			>
				<Form layout={"vertical"}>
					<Carousel
						getCarousel={setCarousel}
						params={{
							autoHeight: true,
							loop: false,
							on: {
								slideChange() {
									setIndex(get(this, "activeIndex", 0));
								},
							},
						}}
					>

						{makeSlides()}

					</Carousel>
				</Form>
			</Modal>
		</div>
	);
}

TrebsModalForm.propTypes = {
	form: PropTypes.object,
	updateCurrentOrder: PropTypes.func,
	categories: PropTypes.array,
	title: PropTypes.string,
	subTitle: PropTypes.string,
	icon: PropTypes.node,
	project: PropTypes.string,
	description: PropTypes.string,
	type: PropTypes.string,
	blockStyle: PropTypes.object,
};

TrebsModalForm.defaultProps = {
	form: {},
	updateCurrentOrder: () => Function,
	categories: [],
	title: "",
	subTitle: "",
	icon: <></>,
	project: "",
	description: "",
	type: 0,
	blockStyle: {},
};

export default Form.create({ name: "trebs" })(withTrebs(TrebsModalForm));
