import React from "react";

import TrebsModalForm from "./TrebsModalForm";
import { Icon } from "../Media";
import { useThemeContext } from "../Layout/ThemeContext";

import "./Trebs.scss";
import { removeEmptyKeys } from "../../helpers";

function Trebs() {
	const { theme } = useThemeContext();

	return (
		<div className={"row trebs"} style={{
			backgroundColor: theme.color.body,
		}}>
			<div className={"col-sx-12 col-md-6 d-flex justify-content-center flex-column trebs-item"}
				style={removeEmptyKeys({
					fontFamily: theme.fontFamily,
					fontSize: theme.fontSize.text,
					lineHeight: theme.lineHeight.text,
					color: theme.color.text,
					backgroundColor: theme.color.body,
				})}>
				<TrebsModalForm
					key={"o-zdravii"}
					title={"Записка о здравии"}
					subTitle={"Заказать записку о здравии"}
					icon={<Icon id={"cross"} style={{ fill: "#c5873e" }} />}
					description={`
            <b>Заказная записка</b> – разовое поминовение с выниманием частицы из просфоры на проскомидии во время Божественной литургии.</br>
            <b>Рекомендуемая цена:</b> 10₽/1 имя</br>
            </br>
            <b>Сорокоуст</b> – поминовение, совершаемое в Церкви ежедневно в течение 40 дней на Божественной литургии.</br>
            <b>Рекомендуемая цена:</b> 400₽/1 имя</br>
            </br>
            <b>Годовое поминовение</b> – поминовение, совершаемое в Церкви ежедневно в течение года на Божественной литургии.</br>
            <b>Рекомендуемая цена:</b> 1000₽/1 имя
            `}
					type={"life"}
				/>
			</div>
			<div className={"col-sx-12 col-md-6 d-flex justify-content-center flex-column trebs-item"}
				style={removeEmptyKeys({
					fontFamily: theme.fontFamily,
					fontSize: theme.fontSize.text,
					lineHeight: theme.lineHeight.text,
					color: theme.color.text,
					backgroundColor: theme.color.body,
				})}>
				<TrebsModalForm
					key={"ob-upokoenii"}
					title={"Записка об упокоении"}
					subTitle={"Заказать записку об упокоении"}
					icon={<Icon id={"cross"} style={{ fill: "#3f2512" }} />}
					description={`
            <b>Заказная записка</b> – разовое поминовение с выниманием частицы из просфоры на проскомидии во время Божественной литургии.</br>
            <b>Рекомендуемая цена:</b> 10₽/1 имя</br>
            </br>
            <b>Панихида</b> – особое богослужение, во время которого мы поминаем умерших и просим у Бога прощения их грехов.</br>
            <b>Рекомендуемая цена:</b> 10₽/1 имя</br>
            </br>
            <b>Сорокоуст</b> – поминовение, совершаемое в Церкви ежедневно в течение 40 дней на Божественной литургии.</br>
            <b>Рекомендуемая цена:</b> 400₽/1 имя</br>
            </br>
            <b>Годовое поминовение</b> – поминовение, совершаемое в Церкви ежедневно в течение года на Божественной литургии.</br>
            <b>Рекомендуемая цена:</b> 1000₽/1 имя
          `}
					type={"dead"}
				/>
			</div>
		</div>
	);
}

export default Trebs;
