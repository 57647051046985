/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Form, Input, Row, Col, Button, Select, Checkbox, Modal, notification } from "antd";

import { removeEmptyKeys, translit } from "../../helpers";
import { useThemeContext } from "../Layout/ThemeContext";
import {
	donate,
	cancel,
	makeDonate,
	donateAim,
	anonymDonate,
	formMessages,
} from "../../constants";
import {
	getPhoneNumberValidationRules,
	fundValidationRules,
	getEmailValidationRules,
	getFirstNameValidationRules,
	formBWtheme,
} from "../../utils";

const { Option } = Select;

const UPDATE_CURRENT_ORDER = gql`
	mutation UpdateCurrentOrder(
		$crowdfundingProjectId: ID
		$amount: Int
		$rewards: [ID!]
		$title: String
		$email: String
		$phone: String
		$delivery: String
		$anonymous: Boolean
		$goals: [String!]
		$paymentType: String
		$firstName: String
	) {
		updateCurrentOrder(
			input: {
				amount: $amount
				anonymous: $anonymous
				clientMutationId: ""
				delivery: $delivery
				email: $email
				firstName: $firstName
				goals: $goals
				lastName: ""
				paymentType: $paymentType
				crowdfundingProjectId: $crowdfundingProjectId
				phone: $phone
				rewards: $rewards
				title: $title
			}
		) {
			result
			clientMutationId
			order {
				amount
				state
				phone
				paymentType
				id
				goals
				delivery
				email
				anonymous
				paymentData {
					accountId
					amount
					currency
					data {
						phone
					}
					description
					email
					publicId
					invoiceId
					requireEmail
				}
			}
		}
	}
`;

const CURRENT_ORDER = gql`
	query CurrentOrder {
		currentOrder {
			amount
			state
			phone
			paymentType
			id
			goals
			delivery
			email
			anonymous
			paymentData {
				accountId
				amount
				currency
				data {
					phone
				}
				description
				email
				publicId
				invoiceId
				requireEmail
			}
		}
	}
`;

function UnmappedCrowdfunding({ data, isOpen, handleClose, form, crowdfundingProjectId }) {
	const { theme } = useThemeContext();

	data.donation = data.donation === null ? { steps: [100, 500, 1000],
		default_amount: "1000" } : data.donation;
	let {
		tags,
		donation: { steps, default_amount } = { steps: [],
			default_amount: 100 },
		goals = [],
	} = data;

	// eslint-disable-next-line no-unused-expressions
	goals ? goals = [] : goals;

	const [order, setOrder] = useState(null);
	const [payment, setPayment] = useState(false);
	const [updatedOrder, setUpdatedOrder] = useState(null);
	const [amount, setAmount] = useState(default_amount);
	const [anonymous, setAnonymous] = useState(true);
	const [token, setToken] = useState(null);

	const { data: currentOrderData, loading: loadingCurrentOrder } = useQuery(CURRENT_ORDER); // Get order
	const [
		createCurrentOrder,
		{ data: createdOrderData, loading: loadingCreatedOrder },
	] = useMutation(UPDATE_CURRENT_ORDER); // Create order
	const [
		updateCurrentOrder,
		{ data: updatedOrderData, loading: loadingUpdatedOrder },
	] = useMutation(UPDATE_CURRENT_ORDER); // Update order

	const { getFieldDecorator, setFieldsValue } = form;

	useEffect(() => {
		setToken(localStorage.getItem("serafim_token"));
	}, []);

	useEffect(() => {
		// Get order
		if (currentOrderData && currentOrderData.currentOrder) {
			setOrder(currentOrderData.currentOrder);
		} else {
			createCurrentOrder({
				variables: {
					amount: 0,
					paymentType: "natural",
					crowdfundingProjectId,
				},
			});
		}
	}, [loadingCurrentOrder]);

	useEffect(() => {
		// Create order
		if (createdOrderData && !(currentOrderData && currentOrderData.currentOrder && order)) {
			setOrder(createdOrderData.updateCurrentOrder);
		}
	}, [loadingCreatedOrder]);

	useEffect(() => {
		// Update order
		if (updatedOrderData) {
			setOrder(updatedOrderData.updateCurrentOrder);
			setUpdatedOrder(updatedOrderData.updateCurrentOrder);
		}
	}, [loadingUpdatedOrder]);

	useEffect(() => {
		// Update amount by step
		setFieldsValue({
			amount,
		});
	}, [amount]);

	useEffect(() => {
		// Pay after update
		if (updatedOrder) {
			pay(updatedOrder.order);
		}
	}, [updatedOrder]);

	function pay({ paymentData }) {
		if (typeof cp !== "undefined" && paymentData) {
			// eslint-disable-next-line no-undef
			const widget = new cp.CloudPayments({ language: "ru" });

			widget.charge(
				Object.assign({}, paymentData, {
					skin: "mini",
				}),
				() => {
					setPayment(false);
					handleClose();
				},
				() => {
					notification.info({
						message: "Платёж отменён",
					});
					setPayment(false);
				},
			);
		}
	}

	function handleSubmit(event) {
		event.preventDefault();

		form.validateFields((err, values) => {
			if (!err) {
				const { amount = 10, email = "", phone = "", firstName = "", anonymous = true, goals = [] } = values;

				setPayment(true);

				updateCurrentOrder({
					variables: {
						amount: +amount,
						anonymous,
						firstName,
						delivery: "",
						email,
						goals,
						paymentType: "natural",
						phone: `+7${phone}`,
						crowdfundingProjectId,
						rewards: ["154"],
						title: "",
					},
				});
			}
		});
	}

	function updateAmount(step) {
		setAmount(+amount + +step);
	}

	return (
		<>
			<style>
				{`
					.ant-modal-close-x {
						color: ${theme.color.text};
					}
					.ant-input-group-addon {
						color: white;
					}
				`}
			</style>
			<Modal
				title={makeDonate}
				visible={isOpen}
				onCancel={handleClose}
				width={1000}
				style={removeEmptyKeys({ backgroundColor: theme.color.body })}
				wrapClassName={`modal-wrap-${theme.color.key}`}
				footer={[
					<Row key={"submit-group"} className="gutter-row">
						<Col span={12} className={"pr-2"}>
							<Button
								block
								type="primary"
								onClick={handleClose}
								disabled={payment}
								style={removeEmptyKeys({
									backgroundColor: theme.color.text,
									color: theme.color.body,
								})}
							>
								{cancel}
							</Button>
						</Col>
						<Col span={12} className={"pl-2"}>
							<Button
								block
								type="primary"
								onClick={handleSubmit}
								loading={payment}
								disabled={payment}
								style={removeEmptyKeys({
									backgroundColor: theme.color.text,
									color: theme.color.body,
								})}
							>
								{donate}
							</Button>
						</Col>
					</Row>,
				]}
			>
				<Form layout={"vertical"}>
					<Form.Item>
						{getFieldDecorator("amount", {
							initialValue: amount,
							rules: fundValidationRules,
						})(<Input prefix={"₽"} placeholder={formMessages.fund.placeholder} />)}
						{!!steps?.length && (
							<div
								className={"step-wrapper d-flex flex-row"}
								style={{ position: "absolute",
									right: 0,
									top: 0 }}
							>
								{steps.map((step, idx) => {
									return (
										<Button
											key={`step-item-${idx}`}
											onClick={() => updateAmount(+step)}
											style={removeEmptyKeys({
												backgroundColor: theme.color.text,
												color: theme.color.body,
											})}
										>
											+{step}
										</Button>
									);
								})}
							</div>
						)}
					</Form.Item>
					{!!goals?.length && (
						<Form.Item label={donateAim.label}>
							{getFieldDecorator("target")(
								<Select
									mode="multiple"
									style={{ width: "100%" }}
									placeholder={donateAim.placeholder}
									optionLabelProp="label"
								>
									{tags.map(tag => {
										return (
											<Option key={`${translit(tag)}`} label={tag}>
												{tag}
											</Option>
										);
									})}
								</Select>,
							)}
						</Form.Item>
					)}
					<Form.Item>
						{getFieldDecorator("anonymous", { initialValue: true })(
							<Checkbox
								checked={anonymous}
								onChange={event => setAnonymous(event.target.checked)}
								style={removeEmptyKeys({ color: theme.color.text })}
							>
								{anonymDonate}
							</Checkbox>,
						)}
					</Form.Item>
					{formBWtheme(anonymous, theme.color.text)}
					<Form.Item>
						{getFieldDecorator("firstName", {
							initialValue: "",
							rules: getFirstNameValidationRules(!anonymous),
						})(<Input placeholder={formMessages.firstName.placeholder} disabled={anonymous} />)}
					</Form.Item>
					{!token &&
					<Form.Item>
						{getFieldDecorator("email", {
							initialValue: "",
							rules: getEmailValidationRules(!anonymous),
						})(<Input placeholder={formMessages.email.placeholder} type={"email"} disabled={anonymous} />)}
					</Form.Item>
					}
					{!token && 	<Form.Item>
						{getFieldDecorator("phone", {
							initialValue: "",
							rules: getPhoneNumberValidationRules(!anonymous),
						})(<Input placeholder={formMessages.phone.placeholder} disabled={anonymous} addonBefore={"+7"} />)}
					</Form.Item>
					}
				</Form>
			</Modal>
		</>
	);
}

UnmappedCrowdfunding.propTypes = {
	form: PropTypes.object,
	data: PropTypes.object,
	isOpen: PropTypes.bool,
	handleClose: PropTypes.func,
	crowdfundingProjectId: PropTypes.number,
};

export default Form.create({ name: "crowdfunding" })(UnmappedCrowdfunding);
