import React from "react";
import PropTypes from "prop-types";

import * as Project from "./Project";

export default function CrowdfundingBlock({ data }) {
	return (
		<div className={"row"}>
			{data.map((item, idx) => {
				const kind = item.kind ? item.kind[0].toUpperCase() + item.kind.slice(1) : "Donate";

				return (
					<div key={`project-item-${idx}`} className={"col-sx-12 col-md-4 p-4"}>
						{React.createElement(Project[kind], {
							data: item,
							imgSetting: {
								width: "100%",
								height: "100%",
							},
						})}
					</div>
				);
			})}
		</div>
	);
}

CrowdfundingBlock.propTypes = {
	data: PropTypes.array,
};
