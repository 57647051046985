import React, { useState } from "react";
import { Button } from "antd";
import { Link } from "gatsby";
import { get } from "lodash";
import dayjs from "dayjs";
import PropTypes from "prop-types";

import CrowdfundingModal from "../../index";
import { PureCarousel } from "../../../PureReactCarousel";
import NoScript from "../../../NoScript";
import { formatURL, makeSpeachSponsor, makeSpeachDay, removeEmptyKeys } from "../../../../helpers";
import { useThemeContext } from "../../../Layout/ThemeContext";
import { donate } from "../../../../constants";

import "./Project.scss";

export function Crowdfunding({ data, imgSetting, handleMouseLeave, showButton }) {
	const { theme } = useThemeContext();
	const [isOpen, setIsOpen] = useState(false);
	const [openProject, setOpenProject] = useState(-1);

	function setOpenModal(id) {
		setIsOpen(true);
		setOpenProject(id);
	}

	const { width } = imgSetting;

	const now = dayjs();
	const date_end = dayjs(get(data, "endedAt", "").split("T")[0]);
	let leftDay = 0;

	if (date_end.isAfter(now)) {
		leftDay = date_end.diff(now, "day");
	}

	const buildingSlug = get(data, "slug", "");

	return (
		<div className={`crowdfunding-block-item ${width}`} onMouseLeave={handleMouseLeave}>
			<div className={"crowdfunding-block-item__content"}>
				<Link
					to={formatURL(buildingSlug)}
					title={get(data, "title", "")}
					className={"h3 crowdfunding-block-item__title"}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.h3,
						lineHeight: theme.lineHeight.h3,
						color: theme.color.text,
					})}
				>
					{ get(data, "title", "") }
				</Link>
				<div className={"crowdfunding-block-item__desc "}>
					{get(data, "description", "")}
				</div>
				{data.id === openProject && (
					<CrowdfundingModal
						data={data}
						isOpen={isOpen}
						crowdfundingProjectId={data.id}
						handleClose={() => setIsOpen(false)}
					/>
				)}
				<div className={"crowdfunding-block-item__donate"}>
					<div>
						<span>{get(data, "currentAmount", 0)}</span>
					</div>
					<div className="">
						<span>Собрано из </span>
						<span>{get(data, "required_amount", 0)}</span>
						<span className={"crowdfunding-block-item__currency"}>р</span>
					</div>
				</div>
				<div>
					<span>{makeSpeachSponsor(get(data, "sponsorsCount", 0))}</span>
					<ProgressBar successPercent={get(data, "progress", 0)} />
					<div style={{ display: "flex",
						justifyContent: "space-between" }}>
						<span>{get(data, "progress", 0)} %</span>
						<span>{makeSpeachDay(leftDay)}</span>
					</div>
				</div>
				{showButton && (
					<div className={"crowdfunding-block-item__button"}>
						<Button
							onClick={() => setOpenModal(data.id)}
							className={"justify-self-end mt-auto button-accent"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							Поддержать
						</Button>
					</div>
				)}
			</div>
		</div>
	);
}

Crowdfunding.propTypes = {
	data: PropTypes.object,
	image: PropTypes.array,
	imgSetting: PropTypes.object,
	handleMouseLeave: PropTypes.func,
	showButton: PropTypes.bool,
};

Crowdfunding.defaultProps = {
	data: {},
	image: [],
	imgSetting: {
		width: "100%",
		height: 370,
	},
	handleMouseLeave: () => {},
	showButton: true,
};

export function Donate({ data, image, imgSetting, handleMouseLeave, showButton }) {
	const { theme } = useThemeContext();
	const [isOpen, setIsOpen] = useState(false);
	const [openProject, setOpenProject] = useState(-1);

	function setOpenModal(id) {
		setIsOpen(true);
		setOpenProject(id);
	}

	let images = null;
	if (image && !!image.length) {
		images = image;
	} else if (get(data, "images", []).length > 0) {
		images = get(data, "images", []).map(item => ({ src: item }));
	} else {
		images = [{ src: get(data, "mainImage", "") }];
	}
	const { width, height } = imgSetting;
	const buildingSlug = get(data, "slug", "");

	return (
		<div className={`crowdfunding-block-item ${width}`} onMouseLeave={handleMouseLeave}>
			<div className={"pb-3"}>
				<PureCarousel
					type={"SlideImage"}
					items={images}
					mediaSize={[width, height]}
					params={
						{
							infinite: false,
							popup: true,
							orientation: "Portrait",
						}
					}
					slideRatio={{
						naturalSlideWidth: 1,
						naturalSlideHeight: 1.05,
					}}
					visibleSlides={1}
				/>
			</div>
			<div>
				<Link
					to={formatURL(buildingSlug)}
					title={get(data, "title", "")}
					className={"h4 crowdfunding-block-item__title"}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.h4,
						lineHeight: theme.lineHeight.h4,
						color: theme.color.text,
					})}
				>
					{ get(data, "title", "") }
				</Link>
				<div
					className={"crowdfunding-block-item__desc "}
					style={removeEmptyKeys({
						fontFamily: theme.fontFamily,
						fontSize: theme.fontSize.text,
						lineHeight: theme.lineHeight.text,
						color: theme.color.text,
					})}
				>
					{ get(data, "description", "") }
				</div>
				{data.id === openProject && (
					<CrowdfundingModal
						data={data}
						isOpen={isOpen}
						crowdfundingProjectId={data.id}
						kind={"donate"}
						handleClose={() => setIsOpen(false)}
					/>
				)}
				{showButton && (
					<div className={"crowdfunding-block-item__button"}>
						<Button
							onClick={() => setOpenModal(data.id)}
							className={"justify-self-end mt-auto button-accent d-noscript-none"}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{donate}
						</Button>
						<NoScript>
							<Button
								className={"justify-self-end mt-auto button-accent"}
								disabled
							>
								{donate}
							</Button>
						</NoScript>
						<NoScript>
							<p>Чтобы сделать пожертвование, включите Javascript</p>
						</NoScript>
					</div>
				)}
			</div>
		</div>
	);
}

Donate.propTypes = {
	data: PropTypes.object,
	image: PropTypes.array,
	imgSetting: PropTypes.object,
	handleMouseLeave: PropTypes.func,
	showButton: PropTypes.bool,
};

Donate.defaultProps = {
	data: {},
	image: [],
	imgSetting: {
		width: "100%",
		height: 370,
	},
	handleMouseLeave: () => {},
	showButton: true,
};

function ProgressBar({ successPercent }) {
	return (
		<div className={"crowdfunding-block-item__progress"}>
			<span style={{ width: `${successPercent}%` }}></span>
		</div>
	);
}

ProgressBar.propTypes = {
	successPercent: PropTypes.number,
};
